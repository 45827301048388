import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ApolloError } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useRouter } from "next/router";

import {
  GetShopProfileQuery,
  useGetShopProfileLazyQuery,
} from "shared/generated/graphql";
import { useAuth } from "shared/providers/AuthProvider";

import { ShopStatus } from "../../../../backend/common/shop.types";

const SetupShopContext = createContext<{
  setupShopData?: GetShopProfileQuery;
  error?: ApolloError;
  refetch: () => void;
  isOnboarding: boolean;
  setIsOnboarding: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

export const useSetupShop = () => {
  const context = useContext(SetupShopContext);
  if (!context) {
    throw new Error("useSetupShop must be used within a SetupShopProvider");
  }
  return context;
};

const verifyAccountDetails = (shopData: GetShopProfileQuery) => {
  const shop = shopData.shops_by_pk;
  const accountDetailsData = {
    name: shop?.name,
    email: shop?.email,
    phone: shop?.phone,
    completeAddress: shop?.completeAddress,
  };
  return Object.values(accountDetailsData).some((value) => !value);
};

const verifyBankDetails = (shopData: GetShopProfileQuery) => {
  const bankDetails = shopData.shops_by_pk?.bankDetails[0];
  return !bankDetails?.bankAbnNumber || !bankDetails?.accountNumber;
};

export function ShopSetupProvider({
  disableProvider,
  children,
}: Readonly<{
  disableProvider?: boolean;
  children: React.ReactNode;
}>) {
  const router = useRouter();
  const { shopId } = useAuth();
  const [getShopProfile, { error, refetch }] = useGetShopProfileLazyQuery();
  const [setupShopData, setSetupShopData] = useState<
    GetShopProfileQuery | undefined
  >();
  const [checked, setChecked] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);

  const checkSetupData = useCallback(
    (shopData: GetShopProfileQuery) => {
      if (shopData) {
        if (shopData.shops_by_pk?.status !== ShopStatus.NEW) {
          return;
        }
        const hasMissingAccountDetails = verifyAccountDetails(shopData);
        if (hasMissingAccountDetails) {
          setIsOnboarding(true);
          return router.push("/setup?step=account");
        }
        const hasMissingShopSpecialties =
          !shopData.shops_by_pk?.specialties ||
          shopData.shops_by_pk?.specialties.length === 0;
        if (hasMissingShopSpecialties) {
          setIsOnboarding(true);
          return router.push("/setup?step=services");
        }
        const hasMissingBankDetails = verifyBankDetails(shopData);
        if (hasMissingBankDetails) {
          setIsOnboarding(true);
          return router.push("/setup?step=details");
        }
      }
    },
    [router]
  );

  useEffect(() => {
    const fetchSetupShopData = async () => {
      if (shopId) {
        const { data, error } = await getShopProfile({ variables: { shopId } });
        if (data) {
          setSetupShopData(data);
          if (!checked) {
            await checkSetupData(data);
          }
        }

        if (error) {
          console.error("Error fetching shop setup data", error);
          Sentry.captureException(error);
        }

        return setChecked(true);
      }
    };

    if (!disableProvider) {
      fetchSetupShopData();
    }
  }, [shopId, getShopProfile, checkSetupData, checked, disableProvider]);

  const value = useMemo(() => {
    return { setupShopData, error, refetch, isOnboarding, setIsOnboarding };
  }, [setupShopData, error, refetch, isOnboarding]);

  return (
    <SetupShopContext.Provider value={value}>
      {disableProvider || checked ? children : null}
    </SetupShopContext.Provider>
  );
}
