import Head from "next/head";

export default function PageHead({ title }: { title?: string }) {
  const fullTitle = title
    ? `${title} | ServiceUp for Shops`
    : "ServiceUp for Shops";
  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <title>{fullTitle}</title>
    </Head>
  );
}
